#searchResultsScrollableContainer .infinite-scroll-component {
  /* !important needed to override inline styles added by react-infinite-scroll-component */
  overflow: hidden !important;
  animation: fadein 1.5s;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
