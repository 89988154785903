.segment-table-container {
  display: flex;
  width: 100%;
}

.segment-table {
  width: 100%;
  max-width: 500px;
  border-collapse: collapse;
  white-space: nowrap;
}

.segment-table th {
  padding: 8px;
  text-align: left;
}

.segment-table th.share {
  text-align: right;
  min-width: 70px;
}

.segment-table tr {
  border-bottom: 1px solid #ccc;
}

.segment-table td {
  padding: 8px;
  text-align: left;
}

.segment-table td.share {
  text-align: right;
  min-width: 70px;
}
