.pie-chart {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  cursor: pointer;
}

.pie-chart:hover {
  border: 2px solid #fff9c2;
}
