.custom-react-tooltip {
  padding: 4px 8px !important;
  font-size: 12px;
  line-height: 16px;
  z-index: 100;
}

.custom-react-tooltip-light {
  border-radius: 4px !important;
  padding: 8px !important;
  --x-shadow: 0px 1px 5px rgba(34, 34, 34, 0.2);
  box-shadow: var(--x-ring-shadow, 0 0 #0000), var(--x-shadow) !important;
  color: #222222 !important;
  font-family: proxima-nova, Arial, sans-serif !important;
  font-size: 12px;
  background: #ffffff !important;
  max-width: 280px;
}
