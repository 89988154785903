.timeline {
  position: relative;
}

.timelineWrapper {
  padding: 32px 0 8px;
  position: relative;
}

.timelineBar {
  width: 2px;
  background-color: #0073e6;
  position: absolute;
  background: linear-gradient(to bottom, rgba(0, 115, 230, 0) 0%, #0073e6 10%, #0073e6 90%, rgba(0, 115, 230, 0) 100%);
  left: 90px; /* Position bar right next to the year */
  top: 0;
  bottom: 0; /* Span the entire height */
}

.timelineEvent {
  display: grid;
  grid-template-columns: 77px 50px auto;
  column-gap: 8px;
  row-gap: 20px;
  align-items: start;
  margin-bottom: 20px;
  position: relative;
}

.timelineDot {
  width: 12px;
  height: 12px;
  background-color: #0073e6;
  border-radius: 50%;
  position: absolute;
  left: 0;
  top: 1px;
}

.timelineArrow {
  width: 0;
  height: 0;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-right: 10px solid #0073e6;
  position: absolute;
  right: 0px;
  top: 2px;
}

.timelineArrowBody {
  height: 2px;
  border-radius: 5px;
  width: 100%;
  background-color: #0073e6;
  position: absolute;
  left: 0;
  top: 6px;
}

.timelineContent {
  width: auto;
}
