#headcount-filter .MuiSlider-markLabel {
  @media (max-width: 768px) {
    font-size: var(--font-size-03) !important;
  }

  &[data-index="0"] {
    transform: none !important;
    left: calc(var(--spacing-inner-base) * -1) !important;
  }
  &[data-index="8"] {
    transform: none !important;
    left: unset !important;
    right: calc(var(--spacing-inner-base02) * -1) !important;
    text-wrap: nowrap !important;
  }
}

#headcount-filter #filter-title {
  margin-bottom: 0 !important;
}
