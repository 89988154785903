.mentions-container em {
  background-color: rgba(49, 129, 255, 0.2); /* secondary500 - 20% opacity */
  font-style: normal;
}

.mentions-container em.selected {
  background-color: rgba(49, 129, 255, 0.5); /* secondary500 - 50% opacity */
}

.mentions-container strong {
  font-weight: 600;
}

.text-block > *:first-child {
  margin-top: 0;
}

.text-paragraph {
  margin-top: 16px;
}
