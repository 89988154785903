@media only screen and (max-width: 1499px) {
  .segment {
    grid-template-columns: 1fr 130px 130px 130px 1fr;
  }
}
/* 24" and bigger */
@media only screen and (min-width: 1500px) {
  .segment {
    grid-template-columns: repeat(5, 1fr);
  }
}
