@import "~@alphasights/alphadesign-tokens/dist/css/portal/tokens.css";
@import "~@alphasights/alphadesign-tokens/dist/css/portal/tokens-classes.css";

html {
  font-size: initial;
}

.no-scrollbar::-webkit-scrollbar {
  width: 0px;
  height: 0px;
  display: none;
}

body:has(.ads-body-end [role="tooltip"]) {
  overflow-x: hidden !important;
}

@font-face {
  font-family: "Poynter";
  src: url("fonts/PoynterOSDisp-Semibold.eot?#iefix") format("embedded-opentype"),
    url("fonts/PoynterOSDisp-Semibold.otf") format("opentype"), url("fonts/PoynterOSDisp-Semibold.woff") format("woff"),
    url("fonts/PoynterOSDisp-Semibold.ttf") format("truetype"),
    url("fonts/PoynterOSDisp-Semibold.svg#PoynterOSDisp-Semibold") format("svg");
  font-weight: normal;
  font-style: normal;
}

em.highlighted-text {
  font-style: normal;
  background-color: #fcffc8;
  padding: 0.2em 0.1em;
}

input:focus::-webkit-input-placeholder {
  opacity: 0.5;
}

::-webkit-input-placeholder {
  font-style: normal;
  font-weight: 300;
}

.rbc-current-time-indicator,
.rbc-noallday .rbc-allday-cell {
  display: none;
}

.rbc-day-slot .rbc-events-container {
  margin-right: 20 !important;
}

.rbc-event-label {
  white-space: normal !important;
}

/*
Some !important overrides here are due to ADS being biased
towards the delivery calendar version.

Also, we may need to add an id in the selector to increase
specificity and ensure that the overrides are applied.
*/

#client-portal-root .rbc-event.rbc-selected,
#client-portal-root .rbc-event {
  border: 1px solid !important;
  padding: 2px 5px !important;
}

.rbc-row {
  padding: 0 !important;
  border: 0 !important;
}

#client-portal-root .rbc-header {
  height: unset;
}

#client-portal-root .event-blue {
  color: #fff !important;
  background-color: #0081ff !important;
}

#client-portal-root .event-light-blue {
  color: #0d1730 !important;
  background-color: #eaf2ff !important;
}

.overflow-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
}

.option:hover {
  background-color: var(--color-background-surface-page-hover);
}

::placeholder {
  color: var(--color-text-assistive);
}

.textBox:focus {
  outline-style: solid;
}

.cky-consent-bar {
  display: none;
}
