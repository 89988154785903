@media only screen and (max-width: 1499px) {
  .segment5Columns {
    grid-template-columns: 1fr 130px 130px 130px 1fr;
  }
  .segment4Columns {
    grid-template-columns: 1fr 130px 130px 130px;
  }
  .segment3Columns {
    grid-template-columns: 1fr 130px 130px;
  }
}
/* 24" and bigger */
@media only screen and (min-width: 1500px) {
  .segment5Columns {
    grid-template-columns: repeat(5, 1fr);
  }
  .segment4Columns {
    grid-template-columns: repeat(4, 1fr);
  }
  .segment3Columns {
    grid-template-columns: repeat(3, 1fr);
  }
}
