.company-primer-details-tooltip,
.company-primer-details-tooltip > div {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.company-primer-details-tooltip > div {
  align-items: center;
}

.obfuscated-sensitive-data > * {
  user-select: none;
  filter: blur(7.5px);
  -webkit-filter: blur(7.5px);
}

/* so that pre tags don't push content out, hiding purchase button */
.obfuscated-sensitive-data pre {
  max-width: 344px;
}
